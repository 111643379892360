import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsAuth } from 'src/app/redux/state/auth/slice';
import { useActions, useAppSelector } from 'src/app/redux/utils';
import { useOnClickAwayHandler } from 'src/shared/hooks/useOnClickAwayHandler';
import { Avatar } from '../../../../shared/ui/Avatar/Avatar';
import { OptionsWindow } from '../../../../shared/ui/_option_lists/OptionsWindow/OptionsWindow';
import s from './HeaderAvatar.module.scss';

const menuOptions = [
	{
		name: 'Выйти',
	},
];

menuOptions.unshift({
	name: 'Карточка сотрудника',
});

interface Props {
	isPrivate: boolean;
}

export const HeaderAvatar: React.FC<Props> = props => {
	const { isPrivate } = props;

	const navigate = useNavigate();

	// * Selectors
	const user = useAppSelector(state => state.user_service.user.userInfo?.user);

	// * API
	const { data: userData } = user_serviceAPI.useGetUserGetQuery(
		{
			userid: user?.id,
			includecurrentavatar: true,
			includecommunications: true,
			includecompany: true,
			includedepartment: true,
			includeoffice: true,
			includeposition: true,
			includerole: true,
			locale: 'ru',
		},
		{ skip: !user },
	);

	const userInfo = userData?.body;

	// * Actions
	const { logout } = useActions(actionsAuth);

	// * Avatar menu
	const [menuExposed, setMenuExposed] = useState(false);

	// * Options
	const optionsRef = useRef(null);
	useOnClickAwayHandler(optionsRef, () => setMenuExposed(false));

	// * Render
	return (
		<div
			className={s.container}
			ref={optionsRef}
		>
			<Avatar
				className={s.avatar}
				userAvatar={userInfo?.user?.avatar}
				name={user ? `${user?.lastName} ${user?.firstName}` : 'Ц О'}
				size="small"
				onClick={() => isPrivate && setMenuExposed(!menuExposed)}
			/>

			{menuExposed && (
				<div className={s.options}>
					<OptionsWindow
						keyNames={{
							name: 'name',
							value: 'name',
						}}
						options={menuOptions}
						onOptionClick={option => {
							if (option.name === 'Карточка сотрудника') {
								navigate(`/users/${userInfo?.user.id}`);
							}
							if (option.name === 'Выйти') {
								logout();
							}
						}}
					/>
				</div>
			)}
		</div>
	);
};
